import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Header from '../components/Header';
import selectedTheme from '../theme';
import ProtectedRoute from './ProtectedRouter';
import usePermissions from '../utils/usePermissions';

const FrontPage = lazy(() => import('../pages/FrontPage'));
const ProfilePage = lazy(() => import('../pages/Profile'));
const DistributionPage = lazy(() => import('../pages/Distribution'));
const InformationPage = lazy(() => import('../pages/Information'));
const dashboardPage = lazy(() => import('../pages/Dashboard'));
const ReportsPage = lazy(() => import('../pages/Reports'));
const devPage = lazy(() => import('../pages/Dev'));
const WorkListPage = lazy(() => import('../pages/WorkList'));
const CasePage = lazy(() => import('../pages/DetailCase'));
const DebtorPage = lazy(() => import('../pages/Debtor'));
const CreditorPage = lazy(() => import('../pages/CreditorPage'));
const PullCasePage = lazy(() => import('../pages/CasePull'));
const DelayCasePage = lazy(() => import('../pages/CaseDelay'));
const ObjectCasePage = lazy(() => import('../pages/CaseObject'));
const CaseCCourtPage = lazy(() => import('../pages/CaseCCourt'));
const AdminPage = lazy(() => import('../pages/Admin'));
const CreateUser = lazy(() => import('../pages/CreateUser'));
const User = lazy(() => import('../pages/User'));
const CoPPage = lazy(() => import('../pages/CaseCoP'));
const CreateNewPage = lazy(() => import('../pages/CreateCase'));
const PullDunningFeesPage = lazy(() => import('../pages/PullDunningFees'));
const HelpPage = lazy(() => import('../pages/Help'));
const Notifications = lazy(() => import('../pages/Notifications'));
const NotificationDetailPage = lazy(() =>
  import('../pages/NotificationDetailPage')
);

const Router = () => {
  const {
    loading,
    canReadCase,
    canCreateCase,
    canReadCreditor,
    canUpdateCase,
    canReadReport,
    canReadDistribution,
    canReadInformation,
    canReadUser,
    canReadClientUser,
    canCreateUser,
    canCreateClientUser,
    canReadAllUsers,
    canReadClientUsers,
    canReadDev,
  } = usePermissions();
  // React hook will take some time to load all the permissions from Auth0, till the time "Loading" variable will be true. React set it false once the data is loaded.
  if (loading) {
    return <div>Loading...</div>; // This loading indicator is implemented to check all the permissions are loaded from auth0 before passing them into the ProtectedRoute Component
  }
  return (
    <ThemeProvider theme={selectedTheme}>
      <ScMainWrapper>
        <BrowserRouter>
          <ScHeader>
            <Header />
          </ScHeader>

          <ScMain>
            <Suspense fallback={<></>}>
              <Switch>
                <Route exact path="/" component={FrontPage} />
                <ProtectedRoute
                  exact
                  path="/dashboard"
                  component={dashboardPage}
                  isAuthorised={canReadCase}
                />
                <ProtectedRoute
                  exact
                  path="/collection"
                  component={dashboardPage}
                  isAuthorised={canReadCase}
                />
                <ProtectedRoute
                  exact
                  path="/distribution"
                  component={DistributionPage}
                  isAuthorised={canReadDistribution}
                />
                <ProtectedRoute
                  exact
                  path="/information"
                  component={InformationPage}
                  isAuthorised={canReadInformation}
                />
                <Route exact path="/profile" component={ProfilePage} />
                <ProtectedRoute
                  exact
                  path="/reports"
                  component={ReportsPage}
                  isAuthorised={canReadReport}
                />
                <ProtectedRoute
                  exact
                  path="/dev"
                  component={devPage}
                  isAuthorised={canReadDev}
                />
                <ProtectedRoute
                  exact
                  path="/admin"
                  component={AdminPage}
                  isAuthorised={canReadAllUsers || canReadClientUsers}
                />
                <ProtectedRoute
                  exact
                  path="/createuser"
                  component={CreateUser}
                  isAuthorised={canCreateUser || canCreateClientUser}
                />
                <ProtectedRoute
                  exact
                  path="/user/:userId"
                  component={User}
                  isAuthorised={canReadUser || canReadClientUser}
                />
                <Route exact path="/user/:userId" component={User} />
                <ProtectedRoute
                  exact
                  path="/worklist"
                  component={WorkListPage}
                  isAuthorised={canReadCase}
                />
                <ProtectedRoute
                  exact
                  path="/case/:caseId"
                  component={CasePage}
                  isAuthorised={canReadCase}
                />
                <ProtectedRoute
                  exact
                  path="/debtor/:debtorId"
                  component={DebtorPage}
                  isAuthorised={canReadCase}
                />
                <ProtectedRoute
                  exact
                  path="/create"
                  component={CreateNewPage}
                  isAuthorised={canCreateCase}
                />
                <Route exact path="/help" component={HelpPage} />
                <Route exact path="/notifications" component={Notifications} />
                <Route
                  exact
                  path="/notification/:notificationId"
                  component={NotificationDetailPage}
                />
                <ProtectedRoute
                  exact
                  path="/creditor/:CreditorId"
                  component={CreditorPage}
                  isAuthorised={canReadCreditor}
                />
                <ProtectedRoute
                  exact
                  path="/case/:caseId/pullcase"
                  component={PullCasePage}
                  isAuthorised={canUpdateCase}
                />
                <ProtectedRoute
                  exact
                  path="/case/:caseId/delaycase"
                  component={DelayCasePage}
                  isAuthorised={canUpdateCase}
                />
                <ProtectedRoute
                  exact
                  path="/case/:caseId/objectcase"
                  component={ObjectCasePage}
                  isAuthorised={canUpdateCase}
                />
                <ProtectedRoute
                  exact
                  path="/case/:caseId/claimofpayment"
                  component={CoPPage}
                  isAuthorised={canUpdateCase}
                />
                <ProtectedRoute
                  path="/case/:caseId/ccourtcase"
                  component={CaseCCourtPage}
                  isAuthorised={canUpdateCase}
                />
                <ProtectedRoute
                  path="/case/:caseId/dunningfees"
                  component={PullDunningFeesPage}
                  isAuthorised={canUpdateCase}
                />
              </Switch>
            </Suspense>
          </ScMain>
        </BrowserRouter>
      </ScMainWrapper>
    </ThemeProvider>
  );
};

const ScMainWrapper = styled.div`
  max-height: 100vh;
  display: grid;
  grid-template-areas:
    'header header'
    'main main';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
`;

const ScHeader = styled.header`
  grid-area: header;
  height: 62px;
`;

const ScMain = styled.main`
  grid-area: main;
  margin: 0 auto;
  width: 100%;
  padding: 2em;
  max-width: 1500px;

  @media (max-width: 600px) {
    padding: 2em 0 0 0;
  }
`;

export default Router;
