import { createSlice } from '@reduxjs/toolkit';

export const selectedCreditorSlice = createSlice({
  name: 'selectedCreditor',
  initialState: '',
  reducers: {
    setSelectedCreditor: (state, action) => {
      return action.payload;
    },
  },
});

export default selectedCreditorSlice;
