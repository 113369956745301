/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';

const ScSelect = styled.select`
  display: inline-block;
  font-size: 1em;
  background-color: ${theme.colors.inputBG};
  padding: 1em;
  border: none;
`;

export const Dropdown = ({ children, ...props }) => {
  return <ScSelect {...props}>{children}</ScSelect>;
};

Dropdown.propTypes = {
  children: PropTypes.node,
};
