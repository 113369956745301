/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../Text/Text';
import theme from '../../theme';

const ScLi = styled.li`
  list-style-type: none;
  float: left;
  width: 33%;
  position: relative;
  text-align: center;
  z-index: 0;
  //Circles
  &:before {
    color: white;
    ${(props) => (props.completed ? `content: "✓"` : `content: " "`)};
    font-weight: 400;
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: ${(props) =>
      props.active || props.completed
        ? `3px solid ${theme.colors.primary}`
        : `2px solid #ddd`};
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background: ${(props) =>
      props.completed ? theme.colors.primary : 'white'};
    z-index: 1;
  }

  //Line
  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.primary};
    top: 15px;
    left: 50%;
    z-index: -1;
  }

  &:last-child:after {
    content: none;
  }
`;

export const Step = (props) => {
  const { label, active, completed } = props;

  return (
    <ScLi active={active} completed={completed} className="checkmark">
      <Text variant="subtext">{label}</Text>
    </ScLi>
  );
};

Step.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
