import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import dotenv from 'dotenv';
import { ApolloProvider } from '@apollo/client';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Router from '../router';
import store from '../store/store';
import { useApolloClient } from '../api/gqlClient';
import { selectedCreditorSlice } from '../components/DropDown/selectedCreditorSlice';
import theme from '../theme';

dotenv.config();

const getFaviconEl = () => {
  return document.getElementById('favicon');
};

const App = () => {
  const apolloClient = useApolloClient(store);
  const { user } = useAuth0();
  const { tenant } = user['https://fair.no/claims/appmetadata'];

  // Redirect to corrent theme url if not on localhost

  useEffect(() => {
    console.log(`tenant: ${tenant} - theme.name: ${theme.name} - theme.url: ${theme.url}`); // eslint-disable-line

    if (!tenant) {
      // Tenant not set
      console.log('tenant not set!');  // eslint-disable-line
    } else if (window.location.hostname.includes('localhost')) {
      // Localhost
      console.log('on localhost!');  // eslint-disable-line
    } else if (tenant !== theme.name) {
      // Tenant is set
      // Not on localhost
      // Wrong theme host
      console.log('tenant is not set, not on localhost and wrong theme host');  // eslint-disable-line
      window.location = `https://${tenant}.fair.no`;
    }
    document.title = `${
      theme.name.charAt(0).toUpperCase() + theme.name.slice(1)
    } Portal`;

    // Change favicon on 'amesto' tenant
    const favicon = getFaviconEl();
    if (theme.name === 'amesto') {
      favicon.href = 'https://www.amesto.no/favicon.ico';
    }
    // console.log(JSON.stringify(tenant, null, 2));

    // if (
    //   tenant && // if tenant set in Auth0
    //   tenant !== theme.name && // If tenant and theme different
    //   !window.location.hostname.includes('localhost') // If on localhost
    // ) {
    //   window.location = theme.url;
    // }
  }, [tenant]);

  useEffect(() => {
    // Set default creditor to "Alle firmaer" when site loads
    store.dispatch(selectedCreditorSlice.actions.setSelectedCreditor(''));
  }, [apolloClient]);

  useEffect(() => {}, []);
  if (!user) return null;

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
    </Provider>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <></>,
});
