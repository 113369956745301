import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { loadingBarReducer } from 'react-redux-loading-bar';
import { selectedCreditorSlice } from '../components/DropDown/selectedCreditorSlice';

const combinedReducers = combineReducers({
  selectedCreditor: selectedCreditorSlice.reducer,
  loadingBar: loadingBarReducer,
});

const store = configureStore({
  reducer: combinedReducers,
});

export default store;
