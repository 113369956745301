import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SelectWrapper,
  Select,
  SelectTrigger,
  SelectedCreditor,
  Arrow,
  Options,
  Option,
} from './styled';
import CreditorOption from './CreditorOption';
import { selectedCreditorSlice } from './selectedCreditorSlice';
import store from '../../store/store';

const ARROW = false;

const useVisible = (initVisibility) => {
  const [showOptions, setShowOptions] = useState(initVisibility);
  const ref = useRef(null);

  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // Når man trykker utenfor
      setShowOptions(false);
    } else {
      setShowOptions(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    // GS: Usikker på om vi trenger denne
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return { ref, showOptions, setShowOptions };
};

const DropDown = (props) => {
  const { creditors, defaultKey } = props;

  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState(defaultKey);
  const [hoverKey, setHoverdKey] = useState(defaultKey);
  const { ref, showOptions, setShowOptions } = useVisible(false);

  const creditorIds = [];

  creditors.map((choise) => {
    creditorIds.push(choise.CreditorId);
    return choise;
  });

  const onSelect = (event) => {
    const key = event.target.getAttribute('data-value');
    window.localStorage.setItem('Creditor', [key]);
    setSelectedKey(key);
    setHoverdKey(key);
    setShowOptions(false);
    store.dispatch(selectedCreditorSlice.actions.setSelectedCreditor(key));
    history.push('/collection');
  };
  const selectedCreditor = creditors[creditorIds.indexOf(selectedKey)];

  return (
    <SelectWrapper ref={ref}>
      <Select>
        <SelectTrigger
          onClick={() => {
            setShowOptions(true);
          }}
          tabIndex="0"
          onKeyDown={(e) => {
            const currentIndex = creditorIds.indexOf(hoverKey);

            if (e.key === 'Enter' || e.key === ' ') {
              setShowOptions(!showOptions);
              setSelectedKey(hoverKey);
            } else if (e.key === 'ArrowUp') {
              if (currentIndex <= 0) {
                setHoverdKey(creditorIds[creditorIds.length - 1]);
              } else {
                const newIndex = currentIndex - 1;
                const nextKey = creditorIds[newIndex];
                setHoverdKey(nextKey);
              }
              e.preventDefault();
            } else if (e.key === 'ArrowDown') {
              if (currentIndex >= creditorIds.length - 1) {
                setHoverdKey(creditorIds[0]);
              } else {
                const newIndex = currentIndex + 1;
                const nextKey = creditorIds[newIndex];
                setHoverdKey(nextKey);
              }
              e.preventDefault();
            } else if (e.key === 'Escape') {
              setShowOptions(false);
            }
          }}
        >
          <SelectedCreditor>{selectedCreditor.Name}</SelectedCreditor>
          {ARROW && <Arrow showOptions={showOptions} />}
        </SelectTrigger>
        <Options showOptions={showOptions}>
          {creditors.map((creditor) => {
            return (
              <Option
                tabIndex="0"
                key={creditor.CreditorId}
                data-value={creditor.CreditorId}
                hoverOption={creditor.CreditorId === hoverKey}
                selectedOption={creditor.CreditorId === selectedKey}
                onClick={onSelect}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onSelect(e);
                  }
                }}
              >
                <CreditorOption creditor={creditor} />
              </Option>
            );
          })}
        </Options>
      </Select>
    </SelectWrapper>
  );
};

export default DropDown;
