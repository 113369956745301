/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../Text/Text';
import theme from '../../theme';
import { adjustBrightness } from '../../lib/adjustBrightness';

/** This button is exported and used in LinkButton also */
export const ScButton = styled.button`
  display: inline-block;
  text-decoration: none;
  background-color: ${(props) => {
    if (props.disabled) return theme.colors.gray;
    switch (props.variant) {
      case 'primary':
        return theme.colors.primary;
      case 'warning':
        return theme.colors.warning;
      case 'error':
        return theme.colors.error;
      default:
        return theme.colors.primary;
    }
  }};
  color: white;
  padding: ${(props) => (props.size === 'sm' ? '.5em 1em' : '1em 2em')};
  border-radius: 30px;
  line-height: 1.5;
  border: none;
  font-size: 1em;
  width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};
  transition: background-color ease 150ms;

  &:hover {
    background-color: ${(props) => {
      if (props.disabled) return theme.colors.gray;
      switch (props.variant) {
        case 'primary':
          return adjustBrightness(theme.colors.primary, -20);
        case 'warning':
          return adjustBrightness(theme.colors.warning, -20);
        case 'error':
          return adjustBrightness(theme.colors.error, -20);
        default:
          return adjustBrightness(theme.colors.primary, -20);
      }
    }};
  }
`;

export const IconWrapper = styled(FontAwesomeIcon)`
  margin-left: 1em;
`;

export const Button = ({
  children,
  icon,
  fullWidth,
  variant = 'primary',
  size = 'lg',
  ...props
}) => {
  return (
    <ScButton {...props} isFullWidth={fullWidth} variant={variant} size={size}>
      <Text>{children}</Text>
      {icon && <IconWrapper icon={icon} size={size} />}
    </ScButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.objectOf(Object),
  variant: PropTypes.oneOf(['primary', 'warning', 'error']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'sm']),
};
