/* eslint-disable react/button-has-type */
// NotificationPanel.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotificationPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
// import { faBell } from '@fortawesome/free-solid-svg-icons';

const NotificationPanel = ({ notifications, lastReadMessageId, isVisible }) => {
  return (
    <div className={`notification-panel ${isVisible ? 'visible' : ''}`}>
      {notifications.length === 0 ? (
        <div className="no-notifications">
          <p>Ingen varsler å vise</p>
        </div>
      ) : (
        <>
          <div className="notification-header">
            <span>Varsler</span>
            <div className="notification-header-actions">
              <Link to="/notifications">
                <button className="view-all">Vis alle</button>
              </Link>
            </div>
          </div>
          <ul className="notification-list">
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className={`notification-item ${
                  notification.id > lastReadMessageId ? 'unread' : ''
                }`}
              >
                <div className="notification-icon">
                  <FontAwesomeIcon icon={faBell} className="bell-icon" />
                </div>
                <div className="notification-content">
                  <p className="notification-title">{notification.title}</p>
                  <p className="notification-body">{notification.summary}</p>
                  <div className="notification-actions">
                    <Link to={`/notification/${notification.id}`}>
                      {' '}
                      Se detaljer
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default NotificationPanel;
