import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const ScOverlay = styled.aside`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** This component requires a DOM node with ID #modal-root to be
 *  present in DOM tree. Add it to public/index.html
 */
export const Modal = (props) => {
  const {
    show,
    children,
    onClose,
    domNode = document.getElementById('modal-root'),
  } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    // Focuses our container so the user doesnt lose focus on modal trigging
    if (show) {
      containerRef.current.focus();
    }
  });

  if (!show || !domNode) {
    return null;
  }

  return createPortal(
    <ScOverlay onClick={() => onClose()} role="dialog">
      {/* Prevents modal from closing while clicking on content */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="dialog"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        ref={containerRef}
      >
        {children}
      </div>
    </ScOverlay>,
    domNode
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  domNode: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
