import React from 'react';
import PropTypes from 'prop-types';

export const Option = ({ value, children, ...props }) => {
  return (
    <option value={value} {...props}>
      {children}
    </option>
  );
};

Option.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
