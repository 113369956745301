import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Step } from './Step';

const ScUl = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
`;

export const Stepper = (props) => {
  const { steps, currentStep } = props;

  return (
    <ScUl>
      {steps.map((step, index) => (
        <Step
          key={step}
          label={step}
          active={index === currentStep}
          completed={currentStep >= index}
        />
      ))}
    </ScUl>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
};
