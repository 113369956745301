import styled from 'styled-components';
import theme from '../../theme';

const BORDER = '0px';

export const SelectWrapper = styled.div`
  -webkit-user-select: initial;
  position: relative;
  max-width: 500px;
  min-width: 300px;
`;
export const Select = styled.div`
  position: relative;
`;

export const SelectTrigger = styled.div`
  border-width: ${BORDER};
  border-style: solid;
  border-color: #394a6d;
  position: relative;
  display: block;
  align-items: right;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 300;
  color: #3b3b3b;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  cursor: pointer;
`;

export const SelectedCreditor = styled.div`
  float: right;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media (max-width: 600px) {
    float: none;
  }
`;

export const Options = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 2px; /* Fixes 1px left-gap between Options and Option element */
  right: 2px; /* Fixes 1px right-gap between Options and Option element */
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh - 180px); /* the scrollbar needs height set */
  border: ${BORDER} solid #394a6d;
  border-top: 0;
  opacity: ${(props) => (props.showOptions ? 1 : 0)};
  visibility: ${(props) => (props.showOptions ? 'visible' : 'hidden')};
  pointer-events: ${(props) => (props.showOptions ? 'all' : 'none')};
  transition: all 0.2s;
  z-index: 2000;
  background-color: whitesmoke;
`;

// border: ${(props) => (props.hoverOption ? `4px solid red` : '')};
export const Option = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 10px 0 10px;
  font-size: 20px;
  font-weight: 300;
  color: #3b3b3b;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: whitesmoke;
  :hover {
    cursor: pointer;
    background-color: #b2b2b2;
  }
  border: ${(props) =>
    props.hoverOption
      ? `2px solid ${theme.colors.gray}`
      : `2px solid ${theme.colors.secondaryGray}`};
  color: ${(props) => (props.selectedOption ? `white` : '')};
  background-color: ${(props) => (props.hoverOption ? `#b2b2b2` : '')};
  background-color: ${(props) =>
    props.selectedOption ? `${theme.colors.secondary}` : ''};
`;

export const Arrow = styled.div`
  position: relative;
  height: 15px;
  width: 15px;
  ::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    background-color: #394a6d;
    transition: all 0.2s;
  }
  ::before {
    left: ${(props) => (props.showOptions ? '-5px' : '-5px')};
    transform: ${(props) =>
      props.showOptions ? `rotate(45deg)` : `rotate(-45deg)`};
  }
  ::after {
    left: ${(props) => (props.showOptions ? '5px' : '5px')};
    transform: ${(props) =>
      props.showOptions ? `rotate(-45deg)` : `rotate(45deg)`};
  }
`;
