import React from 'react';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import DropDown from '../DropDown';

const Wrapper = styled.div`
  padding-right: 20px;
`;

/* This query must NOT be changed without also changing the corresponding query in the app.post('graphql') method in the NodeJS api */
const CREDITORS = gql`
  query jcreditors {
    jcreditors
  }
`;

const CreditorSelect = () => {
  const { data, loading } = useQuery(CREDITORS);

  if (loading) return <p>Laster kreditorer</p>;

  if (!data?.jcreditors) return <></>;

  // 0. Calculates sum of Open/Closes cases
  // Reduces the creditor list to an object with summarized values
  const reducer = (accResult, current) => {
    const result = {
      ActiveCaseCount: accResult.ActiveCaseCount + current.ActiveCaseCount,
      CloseCaseCount: accResult.CloseCaseCount + current.CloseCaseCount,
      TotalOutstandingBalance:
        accResult.TotalOutstandingBalance + current.TotalOutstandingBalance,
    };

    return result;
  };

  let sum;
  if (data.jcreditors.length) {
    sum = data.jcreditors.reduce(reducer);
  } else {
    sum = {
      ActiveCaseCount: 0,
      CloseCaseCount: 0,
      TotalOutstandingBalance: 0,
    };
  }

  // 1. Copy data.creditors and add "All" / "Alle firmaer" option
  const kreditors = Object.assign([], data.jcreditors);
  kreditors.push({
    Name: 'Alle firmaer',
    CreditorId: '',
    ActiveCaseCount: sum.ActiveCaseCount,
    CloseCaseCount: sum.CloseCaseCount,
  });

  // 2. Create Creditors Array for indexing
  const creditorIds = [];
  kreditors.map((creditor) => {
    creditorIds.push(creditor.CreditorId);
    return creditor;
  });

  if (!data.jcreditors.length) {
    return <Wrapper>Ingen kreditorer</Wrapper>;
  }

  return (
    <>
      <Wrapper>
        {data.jcreditors.length === 1 ? (
          <p>{data.jcreditors[0].Name}</p>
        ) : (
          <DropDown creditors={kreditors.reverse()} defaultKey="" />
        )}
      </Wrapper>
    </>
  );
};

CreditorSelect.propTypes = {};

export default CreditorSelect;
