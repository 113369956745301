/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import theme from '../../theme';

const ScArticle = styled.article`
  display: inline-block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  padding: 1em;
  background-color: white;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.fullWidthOnMobile &&
    `
      @media (max-width:600px) {
        width: 100%;
      }
  `}
`;

const ScTitle = styled.h1`
  font-family: ${theme.fontFamily};
  display: block;
  margin: 0;
  font-size: 1em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
`;

const IconWrapper = styled(FontAwesomeIcon)`
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.7);
`;

export const Card = ({ title, children, titleIcon, ...props }) => {
  return (
    <ScArticle {...props}>
      {title && (
        <div style={{ display: 'flex', marginBottom: '1em' }}>
          {titleIcon && <IconWrapper icon={titleIcon} />}
          {title && <ScTitle>{title.toUpperCase()}</ScTitle>}
        </div>
      )}

      {children}
    </ScArticle>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  titleIcon: PropTypes.objectOf(Object),
};
