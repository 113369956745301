const { hostname } = window.location;

const themeAmesto = {
  name: 'amesto',
  url: 'https://amesto.fair.no',
  frontPageName: 'Amesto Fair',
  contactEmail: 'business@amesto.no',
  colors: {
    // Colors must be hex to work with adjustBrightness function
    primary: '#233e4c',
    secondary: '#a88157',
    error: '#FF6347', // Tomato color
    warning: '#ffd500', // Bright yellow from design doc
    inputBG: '#f9f9f9', // Soft gray for input backgrounds
    gray: '#494949', // Dark gray for text/icons etc. From design doc
    secondaryGray: '#ededed', // Lighter gray from design doc
    lightGray: '#F2F2F2', // Lightest gray from design doc
    blueGray: '#44546a', // Blue gray from PP input
    darkTeal: '#0b3c49', // Blue/green from PP input

    fpTile1: '#dde1e4', // Blue/green from PP input
    fpTile2: '#e6dadb', // Blue/green from PP input
    fpTile3: '#e9ddce', // Blue/green from PP input
  },
  fonts: {
    general: {
      fontFamily: '"Rubik", sans-serif',
    },
    tabs: {
      color: '#000000',
      fontSize: '12px',
      fontWeight: '900',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontFamily: 'Abhaya Libre, sans-serif',
    },
  },
  fontFamily: '"Rubik", sans-serif',
};
const themeFair = {
  name: 'fair',
  frontPageName: 'fair',
  contactEmail: 'salg@fair.no',
  url: 'https://portal.fair.no',
  colors: {
    // Colors must be hex to work with adjustBrightness function
    primary: '#1db04c', // Bright green from design doc
    secondary: '#0b4c45', // Dark green from design doc
    error: '#FF6347', // Tomato color
    warning: '#ffd500', // Bright yellow from design doc
    inputBG: '#f9f9f9', // Soft gray for input backgrounds
    gray: '#494949', // Dark gray for text/icons etc. From design doc
    secondaryGray: '#ededed', // Lighter gray from design doc
    lightGray: '#F2F2F2', // Lightest gray from design doc
    blueGray: '#44546a', // Blue gray from PP input
    darkTeal: '#0b3c49', // Blue/green from PP input
    fpTile1: '#0b3c49', // Blue/green from PP input
    fpTile2: '#44546a', // Blue/green from PP input
    fpTile3: '#0b4c45', // Blue/green from PP input
    red: '#ff0000', // Red colot for text
  },
  fonts: {
    general: {
      fontFamily: '"Questrial", Helvetica, sans-serif',
    },
    tabs: {
      color: '#000000',
      fontFamily: '"Questrial", Helvetica, sans-serif',
    },
  },
  fontFamily: '"Questrial", Helvetica, sans-serif',
};
console.log(`hostname: ${hostname}`); //eslint-disable-line

const selectedTheme = hostname === 'amesto.fair.no' ? themeAmesto : themeFair;

const theme = selectedTheme;

export default theme;
