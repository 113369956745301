/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../Text/Text';
import theme from '../../theme';

const ScA = styled.a`
  display: inline-block;
  text-decoration: none;
  color: black;
  line-height: 1.5;
  font-weight: bold;

  :hover {
    color: ${theme.colors.primary};
  }
`;

const IconWrapper = styled(FontAwesomeIcon)`
  color: white;
  vertical-align: -0.07em;
`;

const Wrapper = styled.span`
  display: inline-block;
  background-color: ${theme.colors.primary};
  margin-left: 0.7em;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  height: 1.3em;
  width: 1.3em;
  margin-bottom: 0.3em;
`;

export const Link = ({ href, children, icon, ...props }) => {
  return (
    <ScA href={href} {...props}>
      <Text>{children}</Text>
      {icon && (
        <Wrapper>
          <IconWrapper icon={icon} />
        </Wrapper>
      )}
    </ScA>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(Object),
};
