// src/components/BellIcon.js
import React, { useState, useEffect, useRef } from 'react';
import './BellIcon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useMutation, gql } from '@apollo/client';
import NotificationPanel from '../NotificationPanel/NotificationPanel';

const ACK_NOTIFICATION = gql`
  mutation acknowledgeNotification($notificationId: Int!) {
    acknowledgeNotification(notificationId: $notificationId)
  }
`;
const BellIcon = ({ notifications, lastMessageRead }) => {
  const [isPanelVisible, setPanelVisible] = useState(false);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [lastReadMessageId, setLastMessageRead] = useState(lastMessageRead);
  const panelRef = useRef();
  const latestMessageId = notifications[notifications.length - 1]?.id; // Get the latest message ID

  // should not let the message list to grow too much.
  // new message list should be created when it reaches to either 5 messages. (TBD)
  // displaye the unread Message Count
  // 1. If the notifications.length == latestmessageId, display the count as notifications.length - lastMessageRead
  // 2. If latestMessageId > lastMessageRead (new message arrived), display the count as latestMessageId - lastMessageRead
  const unreadMessageCount =
    // eslint-disable-next-line no-nested-ternary
    latestMessageId === notifications.length
      ? notifications.length - lastMessageRead
      : latestMessageId > lastMessageRead &&
        latestMessageId - lastMessageRead > notifications.length
      ? notifications.length
      : latestMessageId - lastMessageRead;

  // acknowledgeNotification mutation
  const [acknowledgeNotification, { loading: _, error: __ }, // eslint-disable-line
  ] = useMutation(ACK_NOTIFICATION, {
    onCompleted() {},
  });
  const togglePanel = () => {
    setPanelVisible(!isPanelVisible);
    setMarkAsRead(true);
    // update user app_metadata with last read message id
    // get the latest message id from the messages list and set that as the last read message id
    if (latestMessageId > lastMessageRead && !markAsRead) {
      acknowledgeNotification({
        variables: { notificationId: latestMessageId },
      });
    }
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      setPanelVisible(false);
      setLastMessageRead(latestMessageId);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      togglePanel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="bell-icon-container"
      onClick={togglePanel}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
      aria-label="Notifications"
      ref={panelRef}
    >
      <FontAwesomeIcon icon={faBell} className="bell-icon" />
      {notifications.length > 0 &&
        !markAsRead &&
        latestMessageId > lastMessageRead && (
          <span className="message-count">{unreadMessageCount}</span>
        )}
      <NotificationPanel
        notifications={notifications}
        isVisible={isPanelVisible}
        lastReadMessageId={lastReadMessageId}
      />
    </div>
  );
};

export default BellIcon;
