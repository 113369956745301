/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../Text/Text';
import theme from '../../theme';

const ScLabel = styled.label`
  cursor: pointer;
`;

/* The native checkbox is visually hidden to allow a custom looking checkbox
    Opacity: 0 is still accessible for screen readers
*/
const HiddenCheckbox = styled.input`
  z-index: 10;
  opacity: 0;
  width: 1px;
  padding: 0;
  position: absolute;
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
`;

/* The element that visually replaces our checkbox */
const ScCheckbox = styled.div`
  z-index: -1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  background-color: ${(props) =>
    props.checked ? theme.colors.primary : theme.colors.inputBG};
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: background-color ease 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = ({ text, checked, value, onChange }) => {
  return (
    <ScLabel>
      <HiddenCheckbox
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <ScCheckbox checked={checked}>
        <Icon size="xs" icon={faCheck} />
      </ScCheckbox>
      {text && <Text>{text}</Text>}
    </ScLabel>
  );
};

Checkbox.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
};
