import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { hideLoading } from 'react-redux-loading-bar';
import store from '../store/store';

const usePermissions = () => {
  const [status, setStatus] = useState({
    loading: true,
    error: false,
    token: 'init token',
    canReadCase: false,
    canCreateCase: false,
    canReadCreditor: false,
    canUpdateCase: false,
    canReadReport: false,
    canReadDev: false,
    canReadUser: false,
    canReadClientUser: false,
    canCreateUser: false,
    canCreateClientUser: false,
    canUpdateUser: false,
    canReadDistribution: false,
    canReadInformation: false,
    canReadAllUsers: false,
    canReadClientUsers: false,
    canReadAllCreditors: false,
  });

  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => {
    setStatus({
      loading: true,
      token: 'getting token...',
      error: false,
      canReadCase: false,
      canCreateCase: false,
      canReadCreditor: false,
      canUpdateCase: false,
      canReadReport: false,
      canReadDev: false,
      canReadUser: false,
      canReadClientUser: false,
      canCreateUser: false,
      canCreateClientUser: false,
      canUpdateUser: false,
      canReadDistribution: false,
      canReadInformation: false,
      canReadAllUsers: false,
      canReadClientUsers: false,
      canReadAllCreditors: false,
    });

    const token = await getAccessTokenSilently();
    const tokenParsed = jwtDecode(token);
    const { permissions } = tokenParsed;
    const canReadCase = permissions?.includes('read:case');
    const canCreateCase = permissions?.includes('create:case');
    const canReadCreditor = permissions?.includes('read:creditor');
    const canUpdateCase = permissions?.includes('update:case');
    const canReadReport = permissions?.includes('read:report');
    const canReadDev = permissions?.includes('read:dev');
    const canReadUser = permissions?.includes('read:user');
    const canCreateUser = permissions?.includes('create:user');
    const canUpdateUser = permissions?.includes('update:user');
    const canReadDistribution = permissions?.includes('read:distribution');
    const canReadInformation = permissions?.includes('read:information');
    const canReadAllUsers = permissions?.includes('read:all_users');
    const canReadClientUsers = permissions?.includes('read:client_users');
    const canReadClientUser = permissions?.includes('read:client_user');
    const canCreateClientUser = permissions?.includes('create:client_user');
    const canReadAllCreditors = permissions?.includes('read:all_creditors');

    setStatus({
      loading: false,
      token,
      error: false,
      canReadCase,
      canCreateCase,
      canReadCreditor,
      canUpdateCase,
      canReadReport,
      canReadDev,
      canReadUser,
      canCreateUser,
      canUpdateUser,
      canReadDistribution,
      canReadInformation,
      canReadAllUsers,
      canReadClientUsers,
      canCreateClientUser,
      canReadClientUser,
      canReadAllCreditors,
    });
  };
  useEffect(() => {
    getToken();
          }, []); //eslint-disable-line

  useEffect(() => {
    return () => {
      store.dispatch(hideLoading());
    };
  }, []);
  return { ...status, getToken };
};

export default usePermissions;
