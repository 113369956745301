import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

export const Wrapper = styled.div`
  pointer-events: none;
  width: 100%;
  line-height: 25px;

  .creditor-name {
    display: block;
    pointer-events: none;
  }

  .creditor-id {
    font-size: 16px;
    pointer-events: none;
    display: inline-block;
  }

  .creditor-stats {
    font-size: 16px;
    display: inline-block;
    float: right;
  }
`;

export const CreditorStats = styled.div`
  flex-grow: 1;
  pointer-events: none;
  background: red;
`;

export const CreditorName = styled.div``;

const CreditorOption = (props) => {
  const { creditor } = props;
  const { user } = useAuth0();

  const isDev = !!user['https://fair.no/claims/appmetadata']?.roles?.includes(
    'dev'
  );

  return (
    <Wrapper>
      {isDev ? (
        <>
          <div className="creditor-name">{creditor.Name}</div>
          <div className="creditor-id">{creditor.CreditorId}</div>
        </>
      ) : (
        <div className="creditor-name">{creditor.Name}</div>
      )}
    </Wrapper>
  );
};

export default CreditorOption;
