/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../theme';

const ScComponent = styled.span`
  line-height: 1.5;
  font-family: ${theme.fontFamily};
  ${(props) => props.textTransform && `text-transform: ${props.textTransform};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
`;

const ScSubtext = styled(ScComponent)`
  color: ${theme.colors.gray};
`;

export const Text = (props) => {
  // Default variant is span because it does not have an semantic meaning
  const { variant = 'span', children, ...rest } = props;

  const variantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    span: 'span',
    subtext: ScSubtext,
  };

  return (
    <ScComponent as={variantMapping[variant]} {...rest}>
      {children}
    </ScComponent>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'subtext',
  ]),
  children: PropTypes.node,
  fontSize: PropTypes.string,
  color: PropTypes.string,
};
