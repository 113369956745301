/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const sizeMapping = {
  sm: '2em',
  md: '4em',
  lg: '6em',
};

const ScImg = styled.img`
  width: ${(props) => sizeMapping[props.size]};
  height: ${(props) => sizeMapping[props.size]};
  border-radius: 50%;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: ${(props) => sizeMapping[props.size]};
  height: ${(props) => sizeMapping[props.size]};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Avatar = (props) => {
  const { src, size = 'md', ...rest } = props;

  if (src) {
    return <ScImg src={src} alt="Avatar picture" {...rest} size={size} />;
  }
  return (
    <IconWrapper size={size}>
      <FontAwesomeIcon icon={faUser} size="lg" />
    </IconWrapper>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
