import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './app/App';

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_FAIR_IDP}
      clientId={process.env.REACT_APP_FAIR_CLIENTID}
      audience={process.env.REACT_APP_FAIR_API}
      redirectUri={window.location.origin}
      scope="app_metadata"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
