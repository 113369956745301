/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Text } from '../../fair-components';
import theme from '../../theme';
import logoFair from '../../assets/fair_logo.png';
import logoAmesto from '../../assets/amesto_logo.png';
import CreditorSelect from '../CreditorSelect';
import useWindowDimensions from '../../lib/useWindowDimensions';
import usePermissions from '../../utils/usePermissions';
import BellIcon from '../BellIcon/BellIcon';
import notifications from '../../data/notifications.json';

const ScWrapper = styled.div`
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 10;

  .ul-list {
    flex-grow: 4; /* default 0 */
    margin: 0;
    padding: 0;
    li {
      display: inline;
      list-style-type: none;
      padding: 1em;
    }

    #workList-list-item {
      margin-left: 3em;
    }

    a:link,
    a:active,
    a:visited {
      color: ${theme.colors.gray};
      text-decoration: none;
    }
    a:hover {
      color: ${theme.colors.primary};
      text-decoration: underline;
    }
  }
`;

const MobileWrapper = styled.div`
  top: 0;
  min-height: 3em;
  height: ${(props) => props.$mobileMenuIsOpen && '100vh'};
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  a:link,
  a:active,
  a:visited {
    color: ${theme.colors.gray};
    text-decoration: none;
  }
  a:hover {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  .ul-list {
    margin: 0;
    padding: 0 1em 0 0;
    text-align: right;
    float: right;
    li {
      list-style-type: none;
      font-size: 1.5em;
    }
  }
`;

const ScFlexWrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;
const Img = styled.img`
  height: 62px;
  padding: 10px;
`;

const AdminBadge = styled.div`
  .item {
    position: relative;
    display: inline-block;
  }

  .notify-badge {
    position: absolute;
    right: 30px;
    top: 40px;
    background: ${theme.colors.primary};
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: white;
    padding: 2px 4px;
    font-size: 8px;
  }
`;

const ScCreditorAvatar = styled.div`
  align-self: flex-end;
  float: right;
  img {
    height: 62px;
    padding: 10px;
  }
`;

const HamburgerButton = styled.button`
  background-color: inherit;
  border: none;
  padding: 1.5em;
  float: right;
`;

const MobileAvatarLink = styled(Link)`
  position: absolute;
  bottom: 2em;
  right: 1em;
`;

const Header = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { user, isAuthenticated } = useAuth0();
  const location = useLocation();
  const loadingCreditor = useSelector((state) => {
    return !!state.loadingBar.default;
  });

  const {
    canReadCase,
    canReadUser,
    canReadClientUser,
    canReadAllUsers,
    canReadClientUsers,
    canReadDev,
    canReadInformation,
    canReadDistribution,
  } = usePermissions();
  const { last_ack_message_id } = user['https://fair.no/claims/appmetadata'];
  const UlList = (
    <ul className="ul-list">
      <>
        {!['/', '/information', '/distribution'].includes(
          location.pathname
        ) && (
          <>
            {canReadInformation && (
              <Link to="/information">
                <li key="information">
                  <Text
                    fontSize={theme.fonts.tabs.fontSize}
                    fontWeight={theme.fonts.tabs.fontWeight}
                    letterSpacing={theme.fonts.tabs.letterSpacing}
                    textTransform={theme.fonts.tabs.textTransform}
                    fontFamily={theme.fonts.tabs.fontFamily}
                    color={
                      location.pathname === '/information'
                        ? theme.colors.primary
                        : ''
                    }
                  >
                    Information
                  </Text>
                </li>
              </Link>
            )}

            {canReadDistribution && (
              <Link to="/distribution">
                <li key="distribution">
                  <Text
                    fontSize={theme.fonts.tabs.fontSize}
                    fontWeight={theme.fonts.tabs.fontWeight}
                    letterSpacing={theme.fonts.tabs.letterSpacing}
                    textTransform={theme.fonts.tabs.textTransform}
                    fontFamily={theme.fonts.tabs.fontFamily}
                    color={
                      location.pathname === '/collection'
                        ? theme.fonts.tabs.color
                        : ''
                    }
                  >
                    Distribution
                  </Text>
                </li>
              </Link>
            )}
            {canReadCase && (
              <Link to="/collection">
                <li key="collection">
                  <Text
                    fontSize={theme.fonts.tabs.fontSize}
                    fontWeight={theme.fonts.tabs.fontWeight}
                    letterSpacing={theme.fonts.tabs.letterSpacing}
                    textTransform={theme.fonts.tabs.textTransform}
                    fontFamily={theme.fonts.tabs.fontFamily}
                    color={
                      location.pathname === '/collection'
                        ? theme.fonts.tabs.color
                        : ''
                    }
                  >
                    Collection
                  </Text>
                </li>
              </Link>
            )}
            {canReadCase && (
              <Link to="/worklist" id="workList-list-item">
                <li key="worklist">
                  <Text
                    fontSize={theme.fonts.tabs.fontSize}
                    fontWeight={theme.fonts.tabs.fontWeight}
                    letterSpacing={theme.fonts.tabs.letterSpacing}
                    textTransform={theme.fonts.tabs.textTransform}
                    fontFamily={theme.fonts.tabs.fontFamily}
                    color={
                      location.pathname === '/worklist'
                        ? theme.colors.primary
                        : ''
                    }
                  >
                    Arbeidsliste
                  </Text>
                </li>
              </Link>
            )}
            <Link to="/help">
              <li key="help">
                <Text
                  fontSize={theme.fonts.tabs.fontSize}
                  fontWeight={theme.fonts.tabs.fontWeight}
                  letterSpacing={theme.fonts.tabs.letterSpacing}
                  textTransform={theme.fonts.tabs.textTransform}
                  fontFamily={theme.fonts.tabs.fontFamily}
                  color={
                    location.pathname === '/help' ? theme.colors.primary : ''
                  }
                >
                  Hjelp
                </Text>
              </li>
            </Link>

            <Link to="/reports">
              <li key="report">
                <Text
                  fontSize={theme.fonts.tabs.fontSize}
                  fontWeight={theme.fonts.tabs.fontWeight}
                  letterSpacing={theme.fonts.tabs.letterSpacing}
                  textTransform={theme.fonts.tabs.textTransform}
                  fontFamily={theme.fonts.tabs.fontFamily}
                  color={
                    location.pathname === '/reports' ? theme.colors.primary : ''
                  }
                >
                  Rapporter
                </Text>
              </li>
            </Link>
          </>
        )}

        {canReadDev && (
          <>
            <Link to="/dev">
              <li key="dev">
                <Text
                  fontSize={theme.fonts.tabs.fontSize}
                  fontWeight={theme.fonts.tabs.fontWeight}
                  letterSpacing={theme.fonts.tabs.letterSpacing}
                  textTransform={theme.fonts.tabs.textTransform}
                  fontFamily={theme.fonts.tabs.fontFamily}
                  color={
                    location.pathname === '/dev' ? theme.colors.primary : ''
                  }
                >
                  Dev
                </Text>
              </li>
            </Link>
          </>
        )}
        {(canReadAllUsers || canReadClientUsers) && (
          <>
            <Link to="/admin">
              <li key="admin">
                <Text
                  fontSize={theme.fonts.tabs.fontSize}
                  fontWeight={theme.fonts.tabs.fontWeight}
                  letterSpacing={theme.fonts.tabs.letterSpacing}
                  textTransform={theme.fonts.tabs.textTransform}
                  fontFamily={theme.fonts.tabs.fontFamily}
                  color={
                    location.pathname === '/admin' ? theme.colors.primary : ''
                  }
                >
                  Administrasjon
                </Text>
              </li>
            </Link>
          </>
        )}
      </>
    </ul>
  );

  if (isAuthenticated && !isMobile) {
    return (
      <ScWrapper>
        <LoadingBar
          style={{ zIndex: 100000, backgroundColor: '#1db04c', height: '5px' }}
        />
        <ScFlexWrapper>
          <Link to="/">
            {theme.name === 'amesto' ? (
              <Img
                src={logoAmesto}
                alt="Amesto Logo"
                className="sidebar-logo"
              />
            ) : (
              <Img src={logoFair} alt="Fair Logo" className="sidebar-logo" />
            )}
          </Link>

          {UlList}

          {(!loadingCreditor || location.pathname !== '/') && (
            <CreditorSelect />
          )}

          <BellIcon
            notifications={notifications}
            lastMessageRead={last_ack_message_id || 0}
          />
          <ScCreditorAvatar>
            <Link to="/profile" key="avatar">
              <div id="user-menu">
                <Avatar src={user.picture} />
                {(canReadUser || canReadClientUser) && (
                  <AdminBadge className="item">
                    <div className="notify-badge">ADMIN</div>
                  </AdminBadge>
                )}
              </div>
            </Link>
          </ScCreditorAvatar>
        </ScFlexWrapper>
      </ScWrapper>
    );
  }

  if (isAuthenticated && isMobile) {
    return (
      <MobileWrapper $mobileMenuIsOpen={mobileMenuIsOpen}>
        <Link to="/">
          {theme.name === 'amesto' ? (
            <Img src={logoAmesto} alt="Amesto Logo" className="sidebar-logo" />
          ) : (
            <Img src={logoFair} alt="Fair Logo" className="sidebar-logo" />
          )}
        </Link>

        {location.pathname !== '/' && (
          <HamburgerButton
            onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
          >
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              color={theme.colors.blueGray}
            />
          </HamburgerButton>
        )}
        <LoadingBar
          style={{ zIndex: 100000, backgroundColor: '#1db04c', height: '5px' }}
        />
        <span style={{ display: mobileMenuIsOpen ? 'block' : 'none' }}>
          {(!loadingCreditor || location.pathname !== '/') && (
            <>
              <span style={{ marginLeft: '10px' }}>Valgt kreditor:</span>
              <CreditorSelect />
            </>
          )}
        </span>
        {mobileMenuIsOpen && (
          <span onClick={() => setMobileMenuIsOpen(false)}>{UlList}</span>
        )}

        {mobileMenuIsOpen && (
          <MobileAvatarLink
            to="/profile"
            key="avatar"
            onClick={() => setMobileMenuIsOpen(false)}
          >
            <Avatar src={user.picture} />
          </MobileAvatarLink>
        )}
      </MobileWrapper>
    );
  }

  return null;
};

Header.propTypes = {};

export default Header;
