/* eslint-disable react/require-default-props */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../theme';

const StyledInput = styled.input`
  background-color: ${theme.colors.inputBG};
  border: none;
  font-size: 1em;
  width: 100%;
  padding: 1em 2em 1em 1em;
  &:focus {
  }
`;

const IconWrapper = styled(FontAwesomeIcon)`
  margin-left: 1em;
`;

const ScSpan = styled.span`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.inputBG};
  border-radius: 5px;
  width: 100%;
`;

export const TextField = ({ icon, ...props }) => {
  return (
    <ScSpan>
      {icon && <IconWrapper icon={icon} />}
      <StyledInput {...props} />
    </ScSpan>
  );
};

TextField.propTypes = {
  icon: PropTypes.objectOf(Object),
};
